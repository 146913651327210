import DataSDK, { injectReqDataSDK } from './data';
import FileSDK, { injectReqFileSDK } from './file';
import FormSDK, { makeFormSDK } from './form';
import CaseSDK, { makeCaseSDK } from './case';
import ConfigSDK, { makeConfigSDK } from './config';
import AuthSDK from './auth';
import UserSDK from './user';
import userSiderSDK from './auth/usersiders';

const createSDK = (base, account, project) => {
  return {
    form: FormSDK(base, account, project),
    case: CaseSDK(base, account, project),
    data: DataSDK(base, account, project, '/'),
    file: FileSDK(base, account, project),
    storagefile: FileSDK(base, account),
    auth: AuthSDK(base, account, project),
    user: UserSDK(base, account),
    usersiders: userSiderSDK(base, account, project),
    config: ConfigSDK(base, account, project),
  };
};

export const createGhostSDK = (req, base, account, project) => {
  const _FormSDK = makeFormSDK(req);
  const _CaseSDK = makeCaseSDK(req);

  return {
    form: _FormSDK(base, account, project),
    case: _CaseSDK(base, account, project),
    data: injectReqDataSDK(req, base, account, '/'),
    file: injectReqFileSDK(req, base, account, project),
  };
};

export { FormSDK, CaseSDK, DataSDK, FileSDK, AuthSDK, ConfigSDK };
export default createSDK;
