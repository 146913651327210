import GDRequest from '@common/gdrequest';

const userSiderSDK = (base, account, project) => {
  const serve = `/accounts/${account}`;

  return {
    getSiders: (username) => GDRequest.get(base, `${serve}/users/`+username+'/authorized_menus/').send(),
    create: (params) => GDRequest.put(base, `${serve}/policies/${params.path}/`, params.data).send(),
    deleteAuth: (params) => GDRequest.del(base, `${serve}/users/${params.user}/policies/${params.path}/`).send(),// participant
    deleteAuthPost: (params) => GDRequest.post(base, `${serve}/users/${params.user}/policies/${params.path}/delete/`).send(),// participant
    // getJoinUsers: ({id}) => {
    //   console.info('id', id)
    //   return GDRequest.get(base, `${serve}/projects/${project}/hxhjb/projects/${id}/`).send()
    //   .then(({ participant }) => {
    //     let temp = {}
    //     console.info('getJoinUsers-participant', participant, temp)
    //     if(participant && typeof participant === 'string') {
    //       temp = { list: participant.split(',') }
    //     }
    //     if(participant instanceof Array) {
    //       temp = { list: participant }
    //     }
    //     return temp
    //   })
    // },
  };
};
export default userSiderSDK;
