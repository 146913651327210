const sider = {
  menu: {
    theme: 'dark',
    mode: 'inline',
    // items: [],
    items: [
      // { 
        // to: '', title: 'lab配置系统', icon: 'shujucaijiyushenyue',
        // sub: [
          { to: '/config/forms', title: '配置类型列表', icon: '' },
          { to: '/config/forms/builder', title: '新增配置类型', icon: '' },
          { to: '/config/configs', title: '配置列表', icon: '' },
          // { to: '', title: '实施工程师配置', icon: '' },
          // { to: '', title: '关于报告编辑器', icon: '' },
        // ]
      // },
    ],
  },
};

export default sider;
