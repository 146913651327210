import React, { Component } from 'react';
import _ from 'lodash';

let modalManager: any = null;

// TODO: 关闭销毁元素
const showModal = function (modal) {
  modal = Object.assign({}, modal, { key: modalManager?.state.modals.length });

  const modalProps = _.reduce(
    ['getContainer', 'wrapClassName'],
    (t, key) => {
      const p = _.get(modalManager?.props, `modalProps.${key}`);
      if (!_.isUndefined(p)) return _.set(t, [key], p);
      return t;
    },
    {}
  );
  if (!_.isEmpty(modalProps)) {
    modal = React.cloneElement(modal, modalProps);
  }

  modalManager?.setState({
    modals: modalManager?.state.modals.concat([modal]),
  });
};

const hideModal = function () {
  if (modalManager && modalManager.state) {
    modalManager.state.modals.pop();
    modalManager.setState({
      modals: [...modalManager.state.modals],
    });
  } else {
    console.error('没有找到ModalManager锚点');
  }
};

const hideAllModal = function () {
  modalManager.setState({
    modals: [],
  });
};

export { showModal, hideModal, hideAllModal };
export default class ModalManager extends Component {
  static show;
  static hide;
  static hideAll;

  state: { modals: any[] } = { modals: [] };
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     modals: []
  //   };
  // }

  componentDidUpdate() {
    // const { modals } = this.state;
  }

  componentDidMount() {
    modalManager = this;
  }

  componentWillUnmount() {
    modalManager = null;
  }

  render() {
    const { modals } = this.state;
    return (
      <div className="dm-modal-manager">
        {modals.length > 0 && modals.map((modal) => modal)}
      </div>
    );
  }
}

ModalManager.show = showModal;
ModalManager.hide = hideModal;
ModalManager.hideAll = hideAllModal;
