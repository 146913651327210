import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import GDRequest from '@common/gdrequest';
import { buildGDAuthInfoBySigninResult } from '@pages/auth/util';
import createSDK, { createGhostSDK } from '@common/sdk';

// import { getServe, getSDK } from '@projects/conf';

const makeGhostReq = (ciphertext) => {
  window['_NativeAccessStore'] = { GD_AUTHINFO: ciphertext };
  const store: any = { in: '_NativeAccessStore', key: 'GD_AUTHINFO' };
  store.encrypt = { algorithm: 'AES', key: 'genedock.cn' };
  return {
    get: (base, url, params) =>
      new GDRequest(base, url, 'GET', params, null, store),
    post: (base, url, params) =>
      new GDRequest(base, url, 'POST', params, null, store),
    del: (base, url, params) =>
      new GDRequest(base, url, 'DELETE', params, null, store),
    put: (base, url, params) =>
      new GDRequest(base, url, 'PUT', params, null, store),
  };
};

export const userSlice = createSlice({
  name: 'user',
  initialState: {} as any,
  reducers: {
    login: (state, action) => {
      const { account, accountName } = action.payload;
      const serve = window.location.origin;
      const sdk = createSDK(`${serve}`, account || accountName, 'default');
      return {
        ...state,
        ...action.payload,
        serveBaseUrl: serve,
        sdk,
      };
    },
    logout: () => {
      return {};
    },
    ghostLogin: (state, action) => {
      const serve = window.location.origin;
      const userName = _.get(action.payload, 'user.user_name');
      const accountName = _.get(action.payload, 'user.account_name');
      const ciphertext = buildGDAuthInfoBySigninResult(action.payload);
      const Req = makeGhostReq(ciphertext);
      return {
        ...state,
        ghost: {
          userName,
          accountName,
          sdk: createGhostSDK(Req, serve, accountName, 'default'),
        },
      };
    },
    ghostLogout: (state) => {
      const { ghost, ...rest } = state;
      return { ...rest };
    },
    changeServeUrl: (state, action) => {
      localStorage.setItem('serve', action.payload);
      return { serveBaseUrl: action.payload };
    },
    userSiders: (state, action) => {
      // debugger
      const { siders } = action.payload;
      return {
        ...state,
        siders,
      };
    }
  },
});

export const { login, logout, ghostLogin, ghostLogout, changeServeUrl, userSiders } =
  userSlice.actions;
export default userSlice.reducer;
