import GDRequest from '@common/gdrequest';

export const makeCaseSDK = (GDRequest) => (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/hxhjb`;

  return {
    list: (query) => GDRequest.get(base, `${serve}/cases/`, query).send(),
    get: (id) => GDRequest.get(base, `${serve}/cases/${id}/`).send(),
    create: (payload) =>
      GDRequest.post(base, `${serve}/cases/`, payload).send(),
    update: (id, payload) =>
      GDRequest.put(base, `${serve}/cases/${id}/`, payload).send(),
    auditing: (ids, status = '审核中', payload = {}) =>
      GDRequest.put(base, `${serve}/cases/auditing/status/`, {
        status,
        case_ids: ids,
        ...payload,
      }).send(),
  };
};
export default makeCaseSDK(GDRequest);
