import Signin from '@pages/auth/Signin';
import BaseLayout from '@containers/layout/BaseLayout';
import Header from '@containers/layout/BaseHeader';

// redirect referer
const routes = [
  { path: 'signin', element: Signin, props: { redirect: '/config/forms/' } },
  {
    auth: true,
    wrapper: BaseLayout,
    wrapperProps: { headerSlot: Header },
    children: [
      {
        path: 'config',
        breadcrumbName: '配置系统',
        children: [
          {
            breadcrumbName: '配置类型管理',
            path: 'forms',
            element: import('@pages/config/form/FormList'),
            children: [
              {
                breadcrumbName: '新增配置类型',
                path: 'builder',
                element: import('@pages/config/form/FormEditer'),
                emits: [
                  {
                    name: 'ChangeLayoutState',
                    // payload: { siderbar: false, breadcrumb: false },
                  },
                ],
              },
              {
                breadcrumbName: '编辑配置类型',
                path: 'builder/:id',
                element: import('@pages/config/form/FormEditer'),
                emits: [
                  {
                    name: 'ChangeLayoutState',
                    // payload: { siderbar: false, breadcrumb: false },
                  },
                ],
              },

              {
                breadcrumbName: '复制配置类型',
                path: 'clone/:id',
                element: import('@pages/config/form/FormEditer'),
                emits: [
                  {
                    name: 'ChangeLayoutState',
                    // payload: { siderbar: false, breadcrumb: false },
                  },
                ],
                props: { cloneMode: true },
              },
              // {
              //   breadcrumbName: '编辑表单',
              //   path: 'clone/:id',
              //   element: import('@pages/config/form/FormEditer'),
              //   emits: [
              //     {
              //       name: 'ChangeLayoutState',
              //       // payload: { siderbar: false, breadcrumb: false },
              //     },
              //   ],
              //   props: { cloneMode: true },
              // },
            ],
          },
          {
            breadcrumbName: '配置管理',
            path: 'configs',
            element: import('@pages/config/config/ConfigList'),
            children: [
              {
                breadcrumbName: '新建配置',
                path: 'builder',
                element: import('@pages/config/config/ConfigEditor'),
                emits: [
                  {
                    name: 'ChangeLayoutState',
                  },
                ],
              },
              {
                breadcrumbName: '编辑配置',
                path: 'builder/:id',
                element: import('@pages/config/config/ConfigEditor'),
                emits: [
                  {
                    name: 'ChangeLayoutState',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
export default routes;
