import cookies from 'browser-cookies';
import { URL, Client } from '@genedock/requests';
import { message } from 'antd'

interface requestParams {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  mode: string;
  headers?: {};
  body?: string | {};
}

function _isJson(str) {
  try {
    var obj = JSON.parse(str);
    if (obj && typeof obj === 'object' && obj !== null) {
      return true;
    }
  } catch (err) {}
  return false;
}

const GD_DEF_STORE = {
  in: '_dataManagerCookie',
  key: 'GD_AUTHINFO',
  encrypt: { algorithm: 'AES', key: 'genedock.cn' },
};

export default class GDRequest {
  base: string;
  url: string;
  method: 'GET';
  data: any;
  repsonseMethod: string = 'json';
  client: any;
  readerProgress?: () => void;

  constructor(
    base = window.location.origin,
    url,
    method,
    data,
    cookieOpts?,
    store = GD_DEF_STORE
  ) {
    this.base = base;
    this.url = url;
    this.data = data;
    this.method = method;

    window._dataManagerCookie = { get: (key) => cookies.get(key) }; // ?新版无法使用 get(key, cookieOpts) ?
    this.client = new Client({
      base,
      credentials: { algorithm: 'GeneDock', store, send: { in: 'Header' } },
    });
  }

  static get(base, url, params?) {
    return new GDRequest(base, url, 'GET', params);
  }

  static post(base, url, data?) {
    return new GDRequest(base, url, 'POST', data);
  }

  static del(base, url, params?) {
    return new GDRequest(base, url, 'DELETE', params);
  }

  static put(base, url, data?) {
    return new GDRequest(base, url, 'PUT', data);
  }

  static consume(reader, contentType, readerProgress) {
    var total = 0;
    var chunks: any[] = [];

    return pump();
    function pump() {
      return reader.read().then(({ done, value }) => {
        if (done) {
          return new Blob(chunks, { type: contentType });
        }

        chunks.push(value);
        total += value.byteLength;
        readerProgress && readerProgress(total);
        return pump();
      });
    }
  }

  checkStatus = (response) => {
    const { repsonseMethod, readerProgress } = this;
    const status = response.status;

    if (status >= 200 && status < 300) {
      if (status === 204) {
        return response.text();
      }
      switch (repsonseMethod) {
        case 'blob':
          // return response.blob();
          // update by Ocean 2022.5.19
          let filename = 'filename'
          const contentType = response.headers.get("Content-Type");
          console.log('contentType', contentType)
          if(contentType === 'application/json') {
            return response
            .text()
            .then((text) => (_isJson(text) ? JSON.parse(text) : text));
          } else {
            const disposition = response.headers.get("Content-Disposition");
            if(disposition && disposition.indexOf('attachment') !== -1) {
              const utf8Rgex = /filename\*=UTF-8\'\'((['"]).*?\2|[^;\n]*)/
              var utf8Match = utf8Rgex.exec(disposition);
              if (utf8Match !== null && utf8Match[1]) {
                filename =  decodeURIComponent(utf8Match[1]);
                // return response.blob().then(d => {
                //   return {
                //     fileName: filename,
                //     data: d
                //   }
                // });
              }
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(disposition);
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
                // return response.blob().then(d => {
                //   return {
                //     fileName: filename,
                //     data: d
                //   }
                // });
              }
            }
            return response.blob().then(d => {
              let url = window.URL.createObjectURL(new Blob([d], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.download = filename
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
              return true
            }).catch(() => {
              return false
            });
          }
          //
        case 'stream':
          return GDRequest.consume(
            response.body.getReader(),
            response.headers.get('Content-Type'),
            readerProgress
          );
        default:
          return response
            .text()
            .then((text) => (_isJson(text) ? JSON.parse(text) : text));
      }
    }

    if (status === 301 || status === 302) {
      // const location = response.headers['Location'];
      // alert('重定向响应.');
      // TODO: 重定向
      // if (location) {
      //   const newRequest = GDRequest.get('', location);
      //   return newRequest.send();
      // }
    }
    if(status === 401) {
      const error: any = new Error(response.statusText);
      error.response = response;
      message.error('没有权限')
      return Promise.reject()
    }

    const error: any = new Error(response.statusText);
    error.response = response;
    return response.text().then((text) => {
      const erros = _isJson(text) ? JSON.parse(text) : { content: text };
      erros.response = response;
      return Promise.reject(erros);
    });
  };

  send({ headers = {} } = {}) {
    const { url, data, method } = this;
    const params: requestParams = { url, method, mode: 'cors', headers };

    if (['GET', 'DELETE'].indexOf(method) > -1) {
      params.url = URL.parse(params.url, data);
    } else {
      const isObj = typeof data === 'object';
      params.body = isObj ? JSON.stringify(data) : data;
      if (isObj) headers['Content-Type'] = 'application/json';
    }
    // 可选择客户端, 或者不做签名处理
    const request = this.client.request(params);

    return fetch(request)
      .then(this.checkStatus)
      .catch((err) => Promise.reject(err));
  }

  blob() {
    this.repsonseMethod = 'blob';
    return this;
  }

  stream(progress) {
    this.repsonseMethod = 'stream';
    this.readerProgress = progress;
    return this;
  }

  sendForm({ headers = {} } = {}) {
    const { url, data, method } = this;
    const params: requestParams = { url, method, mode: 'cors', headers };
    if (!(data instanceof FormData)) {
      console.error('非 FormData 对象, 请使用 send 方法', data);
    }
    params.body = data;

    return fetch(this.client.request(params))
      .then(this.checkStatus)
      .catch((err) => Promise.reject(err));
  }
}
