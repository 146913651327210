import React from "react";

export class Notice {
  list: {
    [key: string]: Map<Function, Function>;
  };
  constructor() {
    this.list = {};
  }

  add(evName: string, callback: Function) {
    const map = this.list[evName] || new Map();
    map.set(callback, callback);
    this.list[evName] = map;
    console.info("[Notice] - Add", evName, map);
  }

  emit(evName: string, params?: string | {}) {
    const map = this.list[evName];
    console.info("[Notice] - emit", map && map.size);
    if (!map) return;

    for (const [key, value] of Array.from(map.entries())) {
      const bool = value(params);
      if (bool === false) return;
    }
  }

  remove(evName: string, callback: Function) {
    const map = this.list[evName];
    if (!map) return;
    map.delete(callback);
    console.info("[Notice] - Del", evName, map);
  }
}

export default React.createContext(new Notice());
