import GDRequest from '@common/gdrequest';
import shield from './shield.util';
import _ from 'lodash';

export const makeDataSDK = (GDRequest) => (base, account, project, gdPath) => {
  const url = `/accounts/${account}/projects/${project}/data${gdPath}`;
  const currSDK = makeDataSDK(GDRequest);

  return {
    type: 'formal',
    setPath: (path) => currSDK(base, account, project, path),
    checkStatus: (statusArray) =>
      GDRequest.get(base, url)
        .send()
        .then(
          (res) => {
            const { type } = res;
            let list = [] as {}[];
            if (type === 'file') list = list.concat([res]);
            if (type === 'directory') list = list.concat(res.data_list || []);
            if (list.length === 0) return false;
            // status || file_status

            const notHit = (file) =>
              statusArray.indexOf(file.file_status || file.status) < 0;
            return !list.filter(notHit).length;
          },
          (err) => {
            const { response } = err;
            return response.status === 404 ? '404' : Promise.reject(err);
          }
        ),
    isExist: () =>
      GDRequest.get(base, url)
        .send()
        .then(
          (res) => true,
          (err) => {
            const { response } = err;
            return response.status === 404 ? false : Promise.reject(err);
          }
        ),
    get: () =>
      GDRequest.get(base, url, { do_list: false })
        .send()
        .then((res) => _.get(res, 'data_list.0', null)),
    list: () => GDRequest.get(base, url).send().then(shield.formal.list),
    all: () => GDRequest.get(base, url).send().then(shield.formal.list),
    create: () => GDRequest.put(base, url).send(),
    rename: (destPath) =>
      GDRequest.post(base, url, { dest_path: destPath }).send(),
    mv: (destPath, name, overwrite = false) => {
      let before = Promise.resolve();
      let coverUrl = url;

      if (name) {
        // 先重命名在移动, 截取上级目录拼接重命名路径
        const parts = gdPath.split('/');
        const isDir = _.last(parts) === '';
        let parentPath = _.dropRight(parts, isDir ? 2 : 1).join('/') + '/';
        if (parts.length < 2)
          return Promise.resolve(new Error(`无法处理的gdPath${gdPath}`));

        const renamePath = `${parentPath}${name}`;
        before = currSDK(base, account, project, gdPath).rename(renamePath);
        coverUrl = `/accounts/${account}/projects/${project}/data${renamePath}`;
        if (isDir) coverUrl += '/';
      }

      return before.then(() =>
        GDRequest.post(base, coverUrl, {
          dest_path: destPath,
          overwrite,
        }).send()
      );
    },
    del: (params) => GDRequest.del(base, url, params).send(),
    delPost: (params) => GDRequest.post(base, url+'/delete/', params).send(),
    archive: () => {
      const url = `/accounts/${account}/projects/${project}/archive${gdPath}`;
      return GDRequest.post(base, url).send();
    },
    restore: () => {
      const url = `/accounts/${account}/projects/${project}/restore${gdPath}`;
      return GDRequest.post(base, url).send();
    },
  };
};

export default makeDataSDK(GDRequest);
