import _ from 'lodash';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '~/hooks';

const RequireAuth = (props) => {
  const { route, children } = props;
  const { pathname } = useLocation();
  const user = useAppSelector((s) => s.user);

  // console.info('location', location);

  if (!route.auth) return children;
  if (!_.has(user, 'accountName'))
    return (
      <Navigate
        to={`/signin?redirect=${encodeURIComponent(pathname)}`}
        replace
      />
    );
  return children;
};

export default RequireAuth;
