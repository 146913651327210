import _ from 'lodash';
import CryptoJS from 'crypto-js';

export const buildGDAuthInfoBySigninResult = (result) => {
  const { access_key_id: key, access_key_secret: secret } = result;
  const userName = _.get(result, 'user.user_name');
  const userInfo = _.get(result, 'user.user_info');
  const accountName = _.get(result, 'user.account_name');

  const ciphertext = CryptoJS.AES.encrypt(
    // JSON.stringify({
    //   userName,
    //   accountName,
    //   access_key_id: key,
    //   access_key_secret: secret,
    //   user: {
    //     user_name: userName,
    //     user_info: userInfo,
    //     account_name: accountName,
    //   },
    // }),
    JSON.stringify({
      name: userName,
      account: accountName,
      access_key_id: key,
      access_key_secret: secret,
      user: {
        user_name: userName,
        user_info: userInfo,
        account_name: accountName,
      },
    }),
    'genedock.cn'
  );

  return ciphertext.toString();
};
export const buildGDAuthSiderByUserSiders = (result) => {
  const siders = _.get(result, 'siders')

  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify({
      siders
    }),
    'genedock.cn'
  );

  return ciphertext.toString();
};
// 格式化菜单
export const formatMenus = (menus) => {
  let sidersmenu = menus
  const siders = sidersmenu.map(item => {
    if(_.size(item['sub'])) formatMenus(item['sub'])
    // 针对lab平台菜单进行path格式化
    if(item['link']) item['link'] = `/lab${item['link']}`;
    return item
  })
  return siders;
}
