import GDRequest from '@common/gdrequest';

const UserSDK = (base, account) => {
  const serve = `/accounts/${account}/projects/default`;
  return {
    list: (query) => GDRequest.get(base, `${serve}/users/`, query).send().then(res => {
      const data = res.data
      return {list: data.users_list}
    }),
    // (query) => GDRequest.get(base, `${serve}/users`, query).send(),
  };
};

export default UserSDK;
