import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {} as any,
  reducers: {
    currentConfigDataId: (state, action) => {
      const { currentConfigDataId } = action.payload;
      
      return {
        ...state,
        currentConfigDataId,
      };
    },
  },
});

export const { currentConfigDataId } = appSlice.actions;
export default appSlice.reducer;
