import BMF from 'browser-md5-file';
import GDRequest from '@common/gdrequest';

export default class Storage {
  constructor(serviceUrl) {
    this.serviceUrl = serviceUrl;
  }

  upload(path, file, progress, proportion = { sum: 0, md5: 0.3, net: 0.7 }) {
    const filePath = `${path}${file.name}`;
    return this.multipart(filePath, file, progress, proportion);
  }

  multipart(filePath, file, progress, proportion) {
    return new Promise((resolve, reject) => {
      const md5cb = (err, checksum) => {
        if (err) return reject(err);

        let start = 0;
        let cSize = 5;
        let overCount = 0;
        let fileChunks = [];
        const fileSize = file.size;
        const chunkSize = window.parseSize('1m', 'b');

        while (start < fileSize) {
          const chunk = file.slice(start, start + chunkSize);
          start += chunkSize;
          fileChunks.push(chunk);
        }
        const count = fileChunks.length;

        fileChunks = Array.apply(null, {
          length: Math.ceil(count / cSize)
        }).map((v, num) => fileChunks.slice(num * cSize, num * cSize + cSize));

        return this.createMultipartUploadId(filePath, fileSize, checksum)
          .then(uploadId => {
            return fileChunks.reduce((p, chunks, num) => {
              return p.then(result => {
                const promiseChunk = Promise.all(
                  chunks.map((chunk, idx) =>
                    this.putPart(uploadId, num * cSize + idx, chunk).then(
                      pres => {
                        const ratio =
                          proportion.sum +
                          (overCount++ / count) * proportion.net;
                        progress && progress(ratio);
                        return { part_number: pres.num, md5: pres.md5 };
                      }
                    )
                  )
                );

                return promiseChunk.then(parts =>
                  Object.assign(result, {
                    parts: result.parts.concat(parts)
                  })
                );
              });
            }, Promise.resolve({ uploadId, parts: [] }));
          })
          .then(({ uploadId, parts }) =>
             this.completeMultipartUpload(uploadId, file.name, parts)
          )
          .then((res) => {
            progress && progress(1);
            resolve(res);
          }, reject);
      };

      return new BMF().md5(file, md5cb, num => {
        const { sum, md5 } = proportion;
        proportion.sum = num * md5;
        progress && progress(sum);
      });
    });
  }

  createMultipartUploadId(filePath, size, checksum) {
    const multipartREST = `/clinic_storage/multipart_upload/`;
    return GDRequest.post(this.serviceUrl, multipartREST, {
      filesize: size,
      filepath: filePath,
      md5: checksum,
      overwrite: true
    })
      .send()
      .then(id => id.replace(/"/g, ''));
  }

  putPart(uploadId, num, chunk) {
    const formData = new FormData();
    formData.append('part_number', num);
    formData.append('partFile', chunk);

    return GDRequest.put(
      this.serviceUrl,
      `/clinic_storage/multipart_upload/${uploadId}`,
      formData
    )
      .sendForm()
      .then(md5 => ({
        num,
        md5: md5.replace(/"/g, '')
      }));
  }

  completeMultipartUpload(uploadId, name, parts) {
    return GDRequest.post(
      this.serviceUrl,
      `/clinic_storage/multipart_upload/${uploadId}`,
      { parts, file_name: name },
      'clinic_storage'
    ).send();
  }
}
