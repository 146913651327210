import FormalAPI from './formal.api';
import StorageAPI from './storage.api';

const FileSDK = function (opts) {
  let base, account, project;
  switch (arguments.length) {
    case 1:
      if (typeof opts === 'string') {
        console.error('无法处理的参数列表');
        return {};
      }

      ({ base, account, project } = opts);
      return new FormalAPI(base, account, project);
    case 2:
      [base, account] = arguments;
      return new StorageAPI(base)
    case 3:
      [base, account, project] = arguments;
      return new FormalAPI(base, account, project);
    default:
      console.error('无法处理的参数列表');
      break;
  }
};

export const injectReqFileSDK = (req, ...opts) => {
  const sdk = FileSDK.apply(null, opts);
  sdk['req'] = req;
  return sdk;
};

export default FileSDK;
