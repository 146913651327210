import GDRequest from '@common/gdrequest';
import _ from 'lodash';

export const makeFormSDK = (GDRequest) => (base, account, project) => {
  // const serve = `/accounts/${account}/projects/${project}/hxhjb`;
  const serve = `/accounts/${account}/projects/${project}/configapi`;

  return {
    getConfig: (query) => GDRequest.get(base, `/configapi/config/`, query).send(),
    get: (id, query) => GDRequest.get(base, `${serve}/config_meta/${id}/`, query).send(),
    // 查找表单是否已存在
    has: (name) =>
      GDRequest.get(base, `${serve}/forms/`, { name })
        .send()
        .then(
          (res) =>
            _.has(res, 'results.0.name') &&
            _.get(res, 'results.0.name') === name
        ),
    del: (id, payload) => GDRequest.del(base, `${serve}/config_meta/${id}/`, payload).send(),
    delconfigmeta: (id, payload) => GDRequest.post(base, `${serve}/config_meta/${id}/delete/?account=${payload.account}&user=${payload.user}`).send(),
    list: (query) => GDRequest.get(base, `${serve}/config_meta/`, query).send(),
    create: (payload) =>
      GDRequest.post(base, `${serve}/config_meta/`, payload).send(),
    update: (id, payload) =>
      GDRequest.put(base, `${serve}/config_meta/${id}/`, payload).send(),
    // 创建配置
    createOptios: (payload) =>
      GDRequest.post(base, `${serve}/config_data/`, payload).send(),
    updateOptions: (id, payload) =>
      GDRequest.put(base, `${serve}/config_data/${id}/`, payload).send(),
    // 获取当前类型的配置list
    getList: (payload) =>
      GDRequest.get(base, `${serve}/config_data/`, payload).send(),
    getOptionData: (id, payload) =>
      GDRequest.get(base, `${serve}/config_data/${id}/`, payload).send(),
     
  };
};

export default makeFormSDK(GDRequest);
