import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { Layout, Card, ConfigProvider } from 'antd';
import { useAppSelector } from '~/hooks';
import Breadcrumb from '@components/Breadcrumb';
import Sidebar from '@containers/layout/Sidebar';
import siderConfigs from '@projects/sider';
import NoticeContext from '@containers/NoticeContext';

import zhCN from 'antd/lib/locale/zh_CN';
const { Content, Header, Footer } = Layout;

const contextStyles = {
  height: '100%',
  padding: '0 10px 40px 26px',
  overflowY: 'auto' as any,
};

const crumbWrapperStyles = {
  height: '42px',
  padding: '10px 26px',
  boxShadow: 'inset 0 7px 9px -7px rgba(0,0,0,0.4)',
};

interface BaseLayoutProps {
  headerSlot: React.ReactElement;
  sider: {};
  routes: any[];
  hideSider?: boolean;
  hideHeader?: boolean;
  children: React.ReactElement;
}

const defaultState = { siderbar: true, breadcrumb: true };

const BaseLayout = (props: BaseLayoutProps) => {
  // const { headerSlot = '标题', sider = siderConfigs, routes, children } = props;
  let { headerSlot = '标题', sider = siderConfigs, routes, children } = props;
  const { hideHeader, hideSider } = props;
  const [once, setOnce] = useState(true);
  const [state, setState] = useState({ ...defaultState });
  const noticeContext = useContext(NoticeContext);

  const user = useAppSelector((s) => s.user);
  const {siders: items} = user
  // sider.menu.items = items

  const handleNotice = useCallback((payload?) => {
    let next = { ...defaultState };
    if (!_.isEmpty(payload)) next = { ...payload };
    setState(next);
    console.info('layout change state', next);
  }, []);

  if (once) {
    noticeContext.add('CustomRouteChange', handleNotice);
    noticeContext.add('ChangeLayoutState', handleNotice);
  }

  useEffect(() => {
    setOnce(false);
    return () => {
      noticeContext.remove('CustomRouteChange', handleNotice);
      noticeContext.remove('ChangeLayoutState', handleNotice);
    };
  }, [noticeContext, handleNotice]);

  return (
    <ConfigProvider locale={zhCN}>
      <Layout style={{ height: '100%'}}>
        <Layout>
          {!hideSider && <Sidebar collapsed={!state.siderbar} {...sider} />}
          <Content style={{ paddingTop: '84px' }}>
            <div style={{marginTop: '-84px', paddingBottom: '20px'}}>
              {!hideHeader && (
                <Header style={{ backgroundColor: 'white', padding: '0 30px' }}>
                  {headerSlot}
                </Header>
              )}
              {/* <div style={{ height: '68px' }}>
                <Card bordered={false} bodyStyle={crumbWrapperStyles}>
                  <Breadcrumb routes={routes} />
                </Card>
              </div> */}
            </div>
            
            <Content style={contextStyles}>{children}</Content>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default BaseLayout;
