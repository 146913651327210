import BMF from 'browser-md5-file';
import OSS from 'ali-oss';
import { Upload } from '@aws-sdk/lib-storage';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { HttpRequest } from '@aws-sdk/types';
import _ from 'lodash';
import GDRequest from '@common/gdrequest';
// // FIXME: ERROR in 6.25dd9.js from UglifyJs
// // Unexpected token: name (Mime) [./node_modules/ali-oss/shims/mime.js:8,0][6.25dd9.js:46946,8]
// // https://github.com/ali-sdk/ali-oss/issues/424
// const OSS = require('ali-oss');

// http://tool.oschina.net/commons

export const MIME = {
  svg: 'image/svg+xml',
  png: 'image/png',
  gif: 'image/gif',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  html: 'text/html; charset=utf-8',
  md: 'text/markdown; charset=utf-8',
  csv: 'text/plain; charset=utf-8',
  tsv: 'text/plain; charset=utf-8',
  ini: 'text/plain; charset=utf-8',
  log: 'text/plain; charset=utf-8',
  vcf: 'text/plain; charset=utf-8',
  bed: 'text/plain; charset=utf-8',
  txt: 'text/plain; charset=utf-8',
  json: 'text/json; charset=utf-8',
};

const fetchBlob = (url, progress) => {
  const params = {
    method: 'GET',
    mode: 'cors',
  };

  function _isJson(str) {
    try {
      var obj = JSON.parse(str);
      if (obj && typeof obj === 'object' && obj !== null) {
        return true;
      }
    } catch (err) {}
    return false;
  }

  const makeConsume = (reader, contentType, readerProgress) => {
    var total = 0;
    var chunks = [];

    return pump();
    function pump() {
      return reader.read().then(({ done, value }) => {
        if (done) return new Blob(chunks, { type: contentType });

        chunks.push(value);
        total += value.byteLength;
        readerProgress && readerProgress(total);
        return pump();
      });
    }
  };

  return fetch(url, params).then((response) => {
    const status = response.status;

    if (status >= 200 && status < 300) {
      if (status === 204) return response.text();
      const reader = response.body.getReader();
      const contextType = response.headers.get('Content-Type');
      return makeConsume(reader, contextType, progress);
    }

    return response.text().then((text) => {
      const erros = _isJson(text) ? JSON.parse(text) : { content: text };
      erros.response = response;
      return Promise.reject(erros);
    });
  });
};

export default class FormalStorage {
  constructor(serviceUrl, accountName, projectName, req = GDRequest) {
    this.serviceUrl = serviceUrl;
    this.accountName = accountName;
    this.projectName = projectName;
    this.req = req;

    this.baseREST = `/accounts/${accountName}/projects/${projectName}/data`;
    this.uploadREST = `/accounts/${accountName}/projects/${projectName}/upload-data`;
    this.downloadREST = `/accounts/${accountName}/projects/${projectName}/download-data`;
  }

  setAccount(accountName) {
    return new FormalStorage(
      this.serviceUrl,
      accountName,
      this.projectName,
      this.req
    );
  }

  upload(
    path,
    file,
    progress,
    proportion = { sum: 0, md5: 0.3, net: 0.7 },
    testing
  ) {
    const bmf = new BMF();
    const handleLoad = (path, file, checksum, resolve, reject) => {
      this.getBlockUploadInfo(path, file, checksum)
        .then((uploadInfo) => {
          const { blockUploadInfo: binfo } = uploadInfo;
          const { entityId, processId, blockId, fileSize } = uploadInfo;
          let uploadPromise = Promise.resolve();

          switch (binfo.protocol) {
            case 'ftp':
            case 'sftp':
              uploadPromise = this.ftpUpload(file, binfo, progress, proportion);
              break;
            case 'oss':
              uploadPromise = this.ossUpload(
                file,
                uploadInfo,
                progress,
                proportion
              );
              break;
            case 's3':
              uploadPromise = this.S3Upload(
                file,
                uploadInfo,
                progress,
                proportion
              );
              break;
            default:
              return Promise.reject(
                new Error(`不识别的协议. ${binfo.protocol}`)
              );
          }

          return uploadPromise.then(() =>
            this.callbackUploadBlockFinish(
              entityId,
              processId,
              blockId,
              checksum,
              fileSize
            )
          );
        })
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    };
    return new Promise((resolve, reject) => {
      testing
        ? handleLoad(path, file, '', resolve, reject)
        : bmf.md5(
            file,
            (err, checksum) => {
              if (err) {
                return reject(err);
              }
              handleLoad(path, file, checksum, resolve, reject);
            },
            (num) => {
              const { sum, md5 } = proportion;
              proportion.sum = num * md5;
              progress && progress(sum);
            }
          );
    });
  }

  fetchUrlByData(data, mode, progress) {
    const filePath = `${data.path}${data.name}`;
    return this.getData(filePath).then(({ fileFormat }) =>
      this.fetchUrl(filePath, mode, progress).then((url) => ({
        url,
        name: data.name,
        format: fileFormat,
        embed: MIME[`${fileFormat}`.toLocaleLowerCase()],
      }))
    );
  }

  // const gdFullPath = 'hxhjb:/home/admin/下压.jpg';
  fetchBlobByPath(gdFullPath, progress) {
    const [pathAccount, fullPath] = gdFullPath.split(':');
    if (!fullPath)
      return Promise.reject(new Error(`非法的GD路径 ${gdFullPath}`));

    const matchAry = fullPath.match(/^(\/.+\/)+([^/]+\.\w+)$/) || [];
    const [full, dirPath, fileName] = matchAry;
    const data = { name: fileName, path: dirPath };

    const tempSDK = new FormalStorage(
      this.serviceUrl,
      pathAccount,
      this.projectName,
      this.req
    );

    return tempSDK.fetchBlobByData(data, progress);
  }

  fetchBlobByData(data, progress) {
    const filePath = `${data.path}${data.name}`;
    return this.getData(filePath).then(({ size, fileName, fileFormat }) =>
      this.fetchUrl(filePath, 'download', null).then((url) => {
        const po = (totalBytes) => progress && progress(totalBytes / size);
        return fetchBlob(url, po).then((blob) => {
          blob.filename = fileName;
          return blob;
        });
      })
    );
  }

  fetchUrl(path, mode, progress) {
    return new Promise((resolve, reject) => {
      this.getBlockDownloadInfo(path).then(
        (downloadInfo) => {
          const { fileName, fileFormat, blockSize, blockDownloadInfo } =
            downloadInfo;
          const file = { name: fileName, format: fileFormat, size: blockSize };

          switch (blockDownloadInfo.protocol) {
            case 'ftp':
            case 'sftp':
              return resolve(
                this.ftpObjectUrl(file, blockDownloadInfo, mode, progress)
              );
            case 'oss':
              return resolve(this.ossUrl(file, blockDownloadInfo, mode));
            case 's3':
              return resolve(this.S3Url(file, blockDownloadInfo, mode));
            default:
              return reject(
                new Error(`不识别的协议. ${blockDownloadInfo.protocol}`)
              );
          }
        },
        (err) => reject(err)
      );
    });
  }

  ossUrl(file, blockDownloadInfo, mode = 'download') {
    const { ossPath, ossHostName, ossBucketName } = this.resolveDataUrl(
      blockDownloadInfo.data_download_url
    );
    const ossClient = this.createOSSClient(
      blockDownloadInfo,
      ossHostName,
      ossBucketName
    );

    const download = {
      'content-disposition': `attachment; filename=${file.name}`,
    };

    const preview = { 'content-type': MIME[file.format] };
    const response = { download, preview }[mode] || download;

    return Promise.resolve(
      ossClient.signatureUrl(ossPath, { response, expires: 3600 })
    );
  }

  ftpObjectUrl(file, blockDownloadInfo, mode = 'download', progress) {
    const { serviceUrl } = this;
    const fileSize = file.size;
    const path = `/tiny_file/download/`;
    const data = {
      name: encodeURI(file.name),
      password: blockDownloadInfo.password,
      user_name: blockDownloadInfo.user_name,
      attachment: mode === 'download',
      data_download_url: blockDownloadInfo.data_download_url,
    };

    // https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream/getReader
    return this.req
      .put(serviceUrl, path, data)
      .stream((totalBytes) => progress && progress(totalBytes / fileSize))
      .send()
      .then((blob) => {
        if (mode === 'download') {
          blob.filename = file.name;
          return blob;
        }
        return URL.createObjectURL(blob);
      });
  }

  S3Url(file, blockDownloadInfo, mode = 'download', progress) {
    const {
      data_download_url: downloadUrl,
      access_key_id: accessKeyId,
      access_key_secret: secretAccessKey,
      security_token: sessionToken,
      storage_addressing_style: storageAddressingStyle
    } = blockDownloadInfo;
    const S3Client = this.createS3Client(
      accessKeyId,
      secretAccessKey,
      sessionToken,
      downloadUrl,
      storageAddressingStyle
    );

    const url = new URL(downloadUrl);
    // const path = url.pathname.split('/').filter((s) => s);
    const [__, bucket, ...keyList] = _.split(url.pathname, '/');
    const download = {
      ResponseContentDisposition: `attachment; filename=${file.name}`,
    };
    const preview = { ResponseContentType: MIME[file.format] };
    const response = { download, preview }[mode] || download;
    const params = {
      // Key: path.join('/'),
      Key: keyList.join('/'),
      Expires: 3600,
      // Bucket: S3Client._hostNameBucket,
      Bucket: bucket,
      ...response,
    };
    const command = new GetObjectCommand(params);

    try {
      return getSignedUrl(S3Client, command);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  resolveDataUrl(resource) {
    const resourceParts = resource.split('://')[1].split('/');
    const length = resourceParts.length;

    const pathPart = [].concat(resourceParts).splice(2, length);
    const ossHostName = resourceParts[0];
    const ossBucketName = resourceParts[1];
    const ossPath = pathPart.join('/');

    return {
      ossPath,
      ossHostName,
      ossBucketName,
      // entityId: resourceParts[length - 2],
      // blockId: resourceParts[length - 1],
    };
  }

  getData(path) {
    const { serviceUrl } = this;
    return this.req
      .get(serviceUrl, `${this.baseREST}${path}`)
      .send()
      .then(
        ({
          entity_id: entityId,
          file_name: fileName,
          file_format: fileFormat,
          size,
        }) => ({ entityId, size, fileName, fileFormat })
      );
  }

  isExist(path) {
    const { serviceUrl } = this;
    return this.req
      .get(serviceUrl, `${this.baseREST}${path}`)
      .send()
      .then(
        (res) => !!res.entity_id,
        (err) => {
          if (err.response.status === 404) {
            return Promise.resolve(false);
          }
          return Promise.reject(err);
        }
      );
  }

  createPath(path) {
    const { serviceUrl } = this;
    const fullPath = `${this.baseREST}${path}`;
    return this.req
      .put(serviceUrl, fullPath)
      .send()
      .then((res) => res.entity_id);
  }

  // payload = { checksum, compression_type: '', block_type: 'default', file_size: fileSize };
  createUploadProcess(entityId, payload) {
    const { serviceUrl } = this;
    const fullPath = `${this.uploadREST}/${entityId}/`;
    return this.req
      .put(serviceUrl, fullPath, payload)
      .send()
      .then((res) => ({
        processId: res.upload_process_id,
        blockId: res.next_block_url.match(/\/blocks\/(\d+)/)[1],
      }));
  }

  // const payload = {upload_process_id, is_end: true};
  createUploadBlock(entityId, blockId, payload) {
    const { serviceUrl } = this;
    const fullPath = `${this.uploadREST}/${entityId}/blocks/${blockId}/`;
    return this.req
      .put(serviceUrl, fullPath, payload)
      .send()
      .then((res) => res.block_upload_info);
  }

  getBlockUploadInfo(path, file, checksum) {
    return this.createPath(`${path}${file.name.replace(/:/g, '_')}`).then(
      (entityId) => {
        const processPayload = {
          checksum,
          compression_type: '',
          block_type: 'default',
          file_size: file.size,
        };

        return this.createUploadProcess(entityId, processPayload).then(
          ({ blockId, processId }) => {
            const blockPayload = { upload_process_id: processId, is_end: true };

            return this.createUploadBlock(entityId, blockId, blockPayload).then(
              (blockUploadInfo) => ({
                blockId,
                entityId,
                processId,
                blockUploadInfo,
                fileSize: file.size,
              })
            );
          }
        );
      }
    );
  }

  createDownloadProcess(entityId) {
    const { serviceUrl } = this;
    const path = `${this.downloadREST}/${entityId}/`;
    return this.req
      .get(serviceUrl, path)
      .send()
      .then((res) => ({
        blocks: res.blocks,
        compressionType: res.compression_type,
      }));
  }

  createDownloadBlock(entityId, blockId) {
    const { serviceUrl } = this;
    const path = `${this.downloadREST}/${entityId}/blocks/${blockId}/`;
    return this.req
      .get(serviceUrl, path)
      .send()
      .then((res) => ({
        blockDownloadInfo: res.block_download_info,
        blockInfo: res.block_info,
      }));
  }

  ftpUpload(file, blockUploadInfo, progress, proportion) {
    const { serviceUrl } = this;
    const path = `/tiny_file/upload/`;
    const formData = new FormData();
    formData.append('inputFile', file);
    formData.append('password', blockUploadInfo.password);
    formData.append('user_name', blockUploadInfo.user_name);
    formData.append('data_upload_url', blockUploadInfo.data_upload_url);

    let count = 0;
    const estimatedTime = window.parseInt(file.size / (1025 * 1024 * 1.5)); // 1M/s
    const interval = window.setInterval(() => {
      count++;
      if (count === estimatedTime) {
        window.clearInterval(interval);
      }

      progress(
        proportion.sum + (count / estimatedTime) * proportion.net * 0.98
      );
    }, 1000);

    return this.req
      .post(serviceUrl, path, formData)
      .sendForm()
      .then((res) => {
        window.clearInterval(interval);
        progress(1);
        return res;
      });
  }

  getBlockDownloadInfo(path) {
    return this.getData(path).then(({ entityId, fileName, fileFormat }) => {
      return this.createDownloadProcess(entityId).then((processInfo) => {
        const { blocks, compressionType } = processInfo;

        if (blocks.length > 1 || compressionType) {
          return Promise.reject(
            new Error(
              `暂不支持分块数为${blocks.length}且压缩类型为${compressionType}的文件下载.`
            )
          );
        }

        const blockId = blocks[0].block_id;
        return this.createDownloadBlock(entityId, blockId).then(
          ({ blockInfo, blockDownloadInfo }) => ({
            entityId,
            fileName,
            fileFormat,
            blockDownloadInfo,
            blockSize: blockInfo.size,
          })
        );
      });
    });
  }

  callbackUploadBlockFinish(entityId, processId, blockId, checksum, fontSize) {
    const { serviceUrl } = this;
    const payload = {
      block_begin: 0,
      block_end: fontSize,
      block_size: fontSize,
      block_checksum: checksum,
      block_system_size: fontSize,
      upload_process_id: processId,
    };

    const path = `${this.uploadREST}/${entityId}/blocks/${blockId}/callback/`;
    return this.req
      .post(serviceUrl, path, payload)
      .send()
      .then(
        (response) => ({ ...response, entityId }),
        (error) => Promise.reject(error)
      );
  }

  createOSSClient(accessInfo, ossHostName, ossBucketName) {
    const {
      access_key_id: ossAccessKeyId,
      access_key_secret: ossAccessKeySecret,
      security_token: ossSecurityToken,
    } = accessInfo;

    return new OSS({
      secure: true,
      bucket: ossBucketName,
      endpoint: `https://${ossHostName}/`,
      stsToken: ossSecurityToken,
      accessKeyId: ossAccessKeyId,
      accessKeySecret: ossAccessKeySecret,
    });
  }

  createS3Client(accessKeyId, secretAccessKey, sessionToken, dataUrl, storageAddressingStyle) {
    const url = new URL(dataUrl);
    let endpoint = url.origin;
    const hostname = url.hostname;
    // bucket 会拼接到上传地址(子域)中, 所以需要在此处取出
    const [bucket, ...restHostName] = hostname.split('.');
    // let endpoint = `${url.protocol}//${restHostName.join('.')}`;
    // if (url.port) endpoint += `:${url.prot}`;

    const client = new S3Client({
      endpoint,
      region: 'us-east-1', // https://github.com/aws/aws-sdk-js-v3/issues/1845
      credentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken
      },
      forcePathStyle: storageAddressingStyle === 'path',
      signer: async () => ({
        sign: async request => {
          if (request.port) {
            request.headers['host'] = `${request.hostname}:${request.port}`;
          }
          const signatureV4 = new SignatureV4({
            credentials: {
              accessKeyId,
              secretAccessKey,
              sessionToken
            },
            region: 'us-east-1',
            service: 's3',
            sha256: Sha256
          });

          const authorizatedRequest = await signatureV4.sign(request);

          return authorizatedRequest;
        }
      })
    });

    // client._hostNameBucket = bucket;
    return client;
  }

  ossUpload(file, uploadInfo, progress, proportion) {
    const { ossPath, ossHostName, ossBucketName } = this.resolveDataUrl(
      uploadInfo.blockUploadInfo.data_upload_url
    );

    const ossClient = this.createOSSClient(
      uploadInfo.blockUploadInfo,
      ossHostName,
      ossBucketName
    );

    return ossClient.multipartUpload(ossPath, file, {
      progress: (p) => {
        progress && progress(proportion.sum + p * proportion.net);
      },
    });
  }

  S3Upload(file, uploadInfo, progress, proportion) {
    const {
      data_upload_url: uploadUrl,
      access_key_id: accessKeyId,
      access_key_secret: secretAccessKey,
      security_token: sessionToken,
      storage_addressing_style: storageAddressingStyle
    } = uploadInfo.blockUploadInfo;

    try {
      const S3Client = this.createS3Client(
        accessKeyId,
        secretAccessKey,
        sessionToken,
        uploadUrl,
        storageAddressingStyle
      );
      const url = new URL(uploadUrl);
      // const path = url.pathname.split('/').filter((s) => s);
      const [__, bucket, ...keyList] = _.split(url.pathname, '/');
      const params = {
        Body: file,
        // Key: path.join('/'),
        Key: keyList.join('/'),
        // Bucket: S3Client._hostNameBucket,
        Bucket: bucket
      };

      const parallelUpload = new Upload({ client: S3Client, params });
      parallelUpload.on('httpUploadProgress', ({ loaded, total }) => {
        const p = loaded / total;
        progress && progress(proportion.sum + p * proportion.net);
      });
      return parallelUpload.done();
    } catch (error) {
      console.info('s3 upload error', error);
      return Promise.reject(error);
    }
  }
}
