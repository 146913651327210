import GDRequest from '@common/gdrequest';
import shield from './shield.util';
import _ from 'lodash';

const storageAPI = (base, path) => {
  const url = '/clinic_storage/dir/';

  return {
    type: 'storage',
    setPath: (path) => storageAPI(base, path),
    checkStatus: (statusArray) =>
      GDRequest.get(base, `${url}list`, { path })
        .send()
        .then(({ type, file_list: list }) => {
          const isNotExist = !type || list.length === 0;
          if (isNotExist) return '404';

          const notHit = (file) => statusArray.indexOf(file.status) < 0;
          return !list.filter(notHit).length;
        }),
    isExist: () =>
      GDRequest.get(base, `${url}list`, { path })
        .send()
        .then(({ type }) => !!type),
    del: (query = {}) =>
      GDRequest.del(base, `${url}`, { file_path: path, ...query }).send(),
    delPost: (query = {}) =>
      GDRequest.post(base, `${url}delete/`, { file_path: path, ...query }).send(),
    get: () => {
      // GDRequest.get(base, url, { do_list: false })
      //   .send()
      //   .then(res => _.get(res, 'data_list.0', null))
      return Promise.resolve({});
    },
    list: (query = {}) =>
      GDRequest.get(base, `${url}list`, { path, ...query })
        .send()
        .then(shield.storage.list),
    all: () =>
      GDRequest.get(base, `${url}list`, { path, division: 0 })
        .send()
        .then(shield.storage.list),
    create: () =>
      GDRequest.put(base, `${url}create`, {
        path: typeof path === 'string' ? [path] : path,
      }).send(),
    rename: (destPath) =>
      GDRequest.post(base, url, {
        path,
        dest_path: destPath,
      }).send(),
    mv: (destPath, name, overwrite = false) => {
      let before = Promise.resolve();
      let coverPath = path;

      if (name) {
        // 先重命名在移动, 截取上级目录凭借重命名路径
        const parts = coverPath.split('/');
        const isDir = _.last(parts) === '';
        let parentPath = _.dropRight(parts, isDir ? 2 : 1).join('/') + '/';
        if (parts.length < 2)
          return Promise.resolve(new Error(`无法处理的gdPath${path}`));

        const renamePath = `${parentPath}${name}`;
        before = storageAPI(base, path).rename(renamePath);
        coverPath = renamePath;
        if (isDir) coverPath += '/';
      }

      return before.then(() =>
        GDRequest.post(base, url, {
          path: coverPath,
          dest_path: destPath,
          overwrite,
        }).send()
      );
    },
  };
};

export default storageAPI;
