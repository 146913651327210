import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { Input, Card, Form, Button, message } from 'antd';
import cookies from 'browser-cookies';
import { buildGDAuthInfoBySigninResult, buildGDAuthSiderByUserSiders, formatMenus } from '@pages/auth/util';
import _ from 'lodash';

import { useAppDispatch } from '~/hooks';
import AuthSDK from '@common/sdk/auth';
import userSiderSDK from '@common/sdk/auth/usersiders';
import { login, userSiders } from '@slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const Signin = (props) => {
  const { redirect } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSigninSuccess = useCallback(
    (result) => {
      const userName = _.get(result, 'user.user_name');
      const userInfo = _.get(result, 'user.user_info');
      const accountName = _.get(result, 'user.account_name');

      // 存储左侧菜单  获取当前用户可见菜单list
      const ciphertext = buildGDAuthInfoBySigninResult(result);
      cookies.set('GD_AUTHINFO', ciphertext.toString());
      dispatch(login({ userName, accountName, userInfo }));
      userSiderSDK(`${window.location.origin}`, accountName, 'default').getSiders(userName).then(res => {
        console.log('res', res)
        const {menu_list} = res.data
        const siders = formatMenus(menu_list)
        // let sidersmenu = menu_list
        // const siders = sidersmenu.map(item => {
        //   if(item['link']) item['link'] = `/lab${item['link']}`;
        //   return item
        // })
        console.log('siders', siders)
        // return
        const ciphertext2 = buildGDAuthSiderByUserSiders({siders});
        // const ciphertext2 = buildGDAuthInfoBySigninResult(result)
        // console.log('ciphertext2', ciphertext2.toString())
        // localStorage.setItem('GD_AUTHSIDERS', ciphertext2.toString());
        setLoading(true);
        // dispatch(login({ userName, accountName, userInfo }));
        dispatch(userSiders({ siders }));
        navigate(redirect || '/');
      })
    },
    [dispatch, navigate]
  );

  const handleLogin = useCallback(() => {
    const resolve = (info) => {
      if (_.has(info, 'errorFields')) return;
      const auth = AuthSDK(`${window.location.origin}/`, '', '');

      setLoading(true);
      if (info.password) {
        const okey = 'genedock.gateway';
        const ciphertext = CryptoJS.AES.encrypt(info.password, okey).toString();
        info.password = ciphertext;
      }
      auth.signin(info).then(handleSigninSuccess, (error) => {
        message.error('登录失败, 用户名或密码错误');
        setLoading(false);
      })
    };

    form.validateFields().then(resolve);
  }, [form, handleSigninSuccess]);

  useEffect(() => {
    const gdauthinfo = cookies.get('GD_AUTHINFO');
    // window.location.href = 'https://www-hxhjb.stg.genedock.com/'
    // console.log('configsignin', conf.signin)
    // return;
    let _env = process.env;
    // console.log('process.env', process.env, process.env.REACT_APP_SIGNINURL, window['_env_'].REACT_APP_SIGNINURL, _env, GetQueryString('redirect'))
    // check if there is env exits in local, otherwise using window._env
    if (window['_env_'].REACT_APP_SIGNINURL) {
      
      console.log('window._env_.REACT_APP_SIGNINURL', window['_env_'].REACT_APP_SIGNINURL)
      const url = window.location.href;
      const searchParams = new window.URLSearchParams(url.indexOf('?') !== -1 && url.substr(url.indexOf('?')) || '');
      const redirect = searchParams.get('redirect')
      window.location.href = redirect && window['_env_'].REACT_APP_SIGNINURL + `?redirect=${encodeURIComponent(window.location.origin +'/#'+ redirect)}` || window['_env_'].REACT_APP_SIGNINURL+ `?redirect=${encodeURIComponent(window.location.origin)}`;
    }
    return;
    // if(process.env.REACT_APP_SIGNINURL) {
    //   window.location.href = process.env.REACT_APP_SIGNINURL;
    //   return;
    // }


    // if (!gdauthinfo) return;
    // try {
    //   const decrypted = CryptoJS.AES.decrypt(gdauthinfo, 'genedock.cn');
    //   const text = decrypted.toString(CryptoJS.enc.Utf8);
    //   const info = JSON.parse(text);
    //   const { name: userName, account: accountName, user } = info;
    //   // const { userName, accountName, user } = info;
    //   dispatch(login({ userName, accountName, userInfo: user }));
    //   navigate(redirect || '/');
    // } catch (error) {
    //   console.error(error);
    // }
    // const auth = AuthSDK(`${window.location.origin}/`, '', '');
    // auth.getLabEndpoint().then((res) => {
    //   console.info('res', res);
    //   window.location.href = res;
    // })
  }, []);

  return '';
  // return (
  //   <div className="loginwrapper">
  //   <Card
  //     title="登录"
  //     style={{ width: '400px', height: '305px', margin: 'auto', position: 'absolute', left: '0', right: '0', top: '0', bottom: '0' }}
  //     onKeyUp={(e) => e.key === 'Enter' && handleLogin()}
  //   >
  //     <Form form={form}>
  //       <Form.Item
  //         label="账户"
  //         name="account"
  //         labelCol={{ span: 6 }}
  //         initialValue=""
  //         rules={[{ required: true, message: '请填写账户' }]}
  //       >
  //         <Input />
  //       </Form.Item>
  //       <Form.Item
  //         label="用户名"
  //         name="username"
  //         labelCol={{ span: 6 }}
  //         initialValue=""
  //         rules={[{ required: true, message: '请填写用户名' }]}
  //       >
  //         <Input />
  //       </Form.Item>
  //       <Form.Item
  //         label="密码"
  //         name="password"
  //         labelCol={{ span: 6 }}
  //         initialValue=""
  //         rules={[{ required: true, message: '请填写密码' }]}
  //       >
  //         <Input.Password />
  //       </Form.Item>
  //     </Form>
  //     <Button
  //       type="primary"
  //       loading={loading}
  //       onClick={handleLogin}
  //       style={{ width: '100%' }}
  //     >
  //       登录
  //     </Button>
  //   </Card>
  //   </div>
  // );
};

export default Signin;
