import FormalAPI, { makeDataSDK } from './formal.api';
import StorageAPI from './storage.api';

const DataSDK = function (...params: any[]) {
  let base, account, project, gdPath;

  switch (arguments.length) {
    case 1:
      ({ base, account, project, gdPath } = params[0]);
      return account && project
        ? FormalAPI(base, account, project, gdPath)
        : StorageAPI(base, gdPath);
    case 2:
      [base, gdPath] = arguments;
      return StorageAPI(base, gdPath);
    case 4:
      [base, account, project, gdPath] = arguments;
      return FormalAPI(base, account, project, gdPath);
    default:
      console.error('无法处理的参数列表');
      break;
  }
};

export const injectReqDataSDK = (req, ...opts) => {
  const SDK = makeDataSDK(req);
  return SDK.apply(null, opts as any);
};

export default DataSDK;
