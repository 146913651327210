import cookies from 'browser-cookies';
import CryptoJS from 'crypto-js';

import { HashRouter } from 'react-router-dom';
import NoticeContext, { Notice } from '@containers/NoticeContext';
import CustomRouter from '@components/CustomRouter';

import routes from '@projects/router';
import ModalManager from '@containers/ModalManager';
import { useAppDispatch } from '~/hooks';
import { login, userSiders } from '@slices/userSlice';
import { formatMenus } from '@pages/auth/util';
import userSiderSDK from '@common/sdk/auth/usersiders';

import '@styles/iconfont/iconfont.css';

const notice = new Notice();

function App() {
  const getRootDomain = () => {
    const [d0, d1] = window.location.hostname.split('.').reverse();
    const obj = [d0, d1].find((item) => item.includes('localhost'))
    if(obj) return d0;
    return [d1, d0].join('.');
  };
  console.log('App')
  const dispatch = useAppDispatch();
  const gdauthinfo = cookies.get('GD_AUTHINFO');
  if (gdauthinfo) {
    try {
      const decrypted = CryptoJS.AES.decrypt(gdauthinfo, 'genedock.cn');
      const text = decrypted.toString(CryptoJS.enc.Utf8);
      const info = JSON.parse(text);
      console.log('gdauthinfo', info)
      // 每次刷新都请求获取菜单接口
      // userSiderSDK(`${window.location.origin}`, info.account, 'default').getSiders(info.name).then(res => {
      //   console.log('res', res)
      //   const {menu_list} = res.data
      //   const siders = formatMenus(menu_list)
      //   // let sidersmenu = menu_list
      //   // const siders = sidersmenu.map(item => {
      //   //   if(item['link']) item['link'] = `/lab${item['link']}`;
      //   //   return item
      //   // })
      //   console.log('App-siders', siders)
      //   dispatch(userSiders({ siders }));
      // })
      // const { name: userName, account: accountName, user } = info;
      // console.log('info', info)
      // const { name: userName, account: accountName} = info;
      let { name: userName, account: accountName, user} = info;
      if(user) {
        userName = user['user_name'];
        accountName = user['account_name'];
      }
      // dispatch(login({...info, userName, accountName, userInfo: info, user: {user_name: userName, user_info: info, account_name: accountName} }));
      dispatch(login({...info, name: userName, account: accountName, userName, accountName, userInfo: {...info, name: userName, account: accountName}, user: {user_name: userName, user_info: {...info, name: userName, account: accountName}, account_name: accountName} }));
      // dispatch(login(info));
      // dispatch(userSiders(siders))
    } catch (error) {
      console.error(error);
    }
  }
  // else {
  //   // 免登录
  //   const gdauthinfo = 'U2FsdGVkX19tAC8FR7izBo/C4ckwHfH8zTO+5R25QV2JQtM5Su+p14EckNm1l7Celaw5GAWVg2TVBjtZNbBOn8ixE40mY6uBMeuiheVP3yiy9GWQW4kthhq0bFKfJ5O75R44U23XcbvZpmQtORjO6PLnQziZXPkbl7ngVxjuf8En4CP19aoMS8B0iz1UmsvhvN1LOH9Ckwn+/Ra3B4gKN8o+SHEUeHQSzGFJnxJijqjyphXYLwMU29+BlxCHW5sTg+bSbE8kYIBtnJEMDHwARBQNB/mBWm6XpcDA1l6ZQbayfNXBTDTq+2s2sO1Ysl+slYI/8kiDoBjmJJRHSqi+F+4EBs6R5anq6YusMm9GjHbOyM9bOmuDY5vRdtuSQ+X1z6+LVR0IKmgy+9UBxkjpMMLaK9XFdewYSU0ckBHEg08zzh9ooJLiidxiOFR/TeP7iez+IF9FBTBg0InLwWVxpLqLnAKUBXNuLuWsQMZZlsNuQbAuZFLO17i6sUWxROqdn8SRvotddYWCWCyzkC0tX92gESPXVNrb4ISEqhfm3NA1CuXT5HCO0kF3QW47fLNlkCGNUhh58rWIfZGpVxhEUsPHcjiEKzmJJ1op6b8ZMGbNO1JlZBS5hUGG08HMuHfTP3ULQUSr7oC4/yW1CBAIzluFj5/vOY5ygXDoNJp424zveL20dUnyPi6/nLm6+ZU8MJf7g5rcOnvzZaG8QRzQdyapkF+TRB2pkP9Naq+fE0LwrgDPzRctEDvixxQau5M8';
  //   console.log('getRootDomain()', getRootDomain())
  //   cookies.set('GD_AUTHINFO', gdauthinfo, {domain: getRootDomain()});
  //   const decrypted = CryptoJS.AES.decrypt(gdauthinfo, 'genedock.cn');
  //   const text = decrypted.toString(CryptoJS.enc.Utf8);
  //   const info = JSON.parse(text);
  //   const { name: userName, account: accountName } = info;
  //   dispatch(login({...info, userName, accountName, userInfo: info, user: {user_name: userName, user_info: info, account_name: accountName} }));
  //   // dispatch(login({ account: 'hxhjb', name: 'admin', userName: 'admin', accountName: 'hxhjb' }));
  // }

  return (
    <HashRouter>
      <NoticeContext.Provider value={notice}>
        <CustomRouter routes={routes} />
        <ModalManager />
      </NoticeContext.Provider>
    </HashRouter>
  );
}

export default App;
