export default {
  formal: {
    list: ({ data_list: list, total, type }) => ({
      list,
      total,
      type,
    }),
  },
  storage: {
    list: ({ file_list: list, files_num: total, page, size, type }) => ({
      list,
      total,
      page,
      size,
      type,
    }),
  },
};
