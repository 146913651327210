import GDRequest from '@common/gdrequest';

export const makeConfigSDK = () => (base, account, project) => {
  const serve = `/accounts/${account}/projects/${project}/configapi`;

  return {
    list: query => GDRequest.get(base, `${serve}/config_data/`, query).send(),
    get: id => GDRequest.get(base, `${serve}/config_data/${id}/`).send(),
    create: payload => GDRequest.post(base, `${serve}/config_data/`, payload).send(),
    update: (id, payload) => GDRequest.put(base, `${serve}/config_data/${id}/`, payload).send(),
    del: (id, payload) => GDRequest.del(base, `${serve}/config_data/${id}/`, payload).send(),
    delconfigdata: (id, payload) => GDRequest.post(base, `${serve}/config_data/${id}/delete/?account=${payload.account}&user=${payload.user}`, {}).send(),
  }
};

export default makeConfigSDK();
