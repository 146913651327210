import { Row, Col, Button } from 'antd';
// import logo from '@assets/hxhjblogo.png';
import cookies from 'browser-cookies';
import _ from 'lodash';

import { useAppSelector } from '~/hooks';

const getRootDomain = () => {
  const [d0, d1] = window.location.hostname.split('.').reverse();
  return [d1, d0].join('.');
};

const BaseHeader = (props) => {
  const user = useAppSelector((s) => s.user);
  const handleMenuClick = (info) => {
    cookies.erase('GD_AUTHINFO');
    // 清除当前用户菜单
    // localStorage.removeItem("GD_AUTHSIDERS")
    document.cookie = `GD_AUTHINFO=; domain=${getRootDomain()}; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    window.location.reload();
  };

  return (
    <Row>
      {/* <Col span={12}>
        { <img src={logo} alt="" style={{ height: '60px' }} />}
        配置系统
      </Col> */}
      {/* <Col span={24} style={{ textAlign: 'right' }}>
        <Row justify='end'>
          <Col>
          <Button
            size="small"
            onClick={handleMenuClick}
          >
            退出登录
          </Button>
          </Col>
        </Row>
        
      </Col> */}
    </Row>
  );
};

export default <BaseHeader />;
